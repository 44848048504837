import { db } from "../../firebase/config";
import { useMusic } from "../../firebase/fbQueries";
import MusicCard from "../common/MusicCard";

function AdminMusicHome() {
  const music = useMusic(db);
  return (
    <>
      <div className="admin-grid">
        {music
          .sort((a, b) => b.date - a.date)
          .map((music, index) => (
            <MusicCard key={index} {...music} />
          ))}
      </div>
    </>
  );
}
export default AdminMusicHome;
