import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { db } from "../../firebase/config";
import VideoCard from "../common/VideoCard";
import VideoCardLoad from "../common/VideoCardLoad";
import { useVideos } from "../../firebase/fbQueries";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { website_name } from "../../database/config";
import YouTubePlayer from "react-player/youtube";

function Videos(props) {
  const { t } = useTranslation();
  const videos = useVideos(db);
  const [searchTerm, setSearchTerm] = useState("");
  const sameTitleVideos = videos.filter((video) => {
    const matchesSearch = video.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    return matchesSearch;
  });

  const [videoModal, setVideoModal] = useState(false);
  const [videoSelected, setVideoSelected] = useState(null);

  const changeVideo = (link, cover) => {
    setVideoSelected(link);
    setVideoModal(!videoModal);
    props.setVideo(!props.video);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <Helmet>
        <title>
          {t("videos.title")} ~ {website_name}
        </title>
        <link rel="canonical" href="https://danielospid.com/videos" />
        <meta property="og:title" content="Vídeos ~ Daniel Ospid" />
        <meta property="og:url" content="https://danielospid.com/videos" />
        <meta name="twitter:title" content="Vídeos ~ Daniel Ospid" />
        <meta name="twitter:url" content="https://danielospid.com/videos" />
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="row-title">
            <h1 hidden>
              {t("title.videos")} de {website_name}
            </h1>
            <h2>{t("title.videos")}</h2>
            <div className="flex-action">
              <div className="select-wrapper">
                <input
                  type="text"
                  placeholder={t("videos.label.search")}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <FontAwesomeIcon icon={faSearch} size="sm" color="#757575" />
              </div>
            </div>
          </div>
          <div className="grid">
            {sameTitleVideos.length === 0 ? (
              <>
                <VideoCardLoad />
                <VideoCardLoad />
                <VideoCardLoad />
                <VideoCardLoad />
                <VideoCardLoad />
                <VideoCardLoad />
                <VideoCardLoad />
                <VideoCardLoad />
                <VideoCardLoad />
              </>
            ) : (
              sameTitleVideos
                .sort((a, b) => b.date - a.date)
                .map((video, index) => (
                  <VideoCard
                    key={index}
                    {...video}
                    index={index}
                    changeVideo={changeVideo}
                  />
                ))
            )}
          </div>
        </div>
      </section>

      <div
        className={!videoModal ? "video-modal" : "video-modal show"}
        onClick={() => changeVideo(null)}
      >
        {videoSelected ? (
          <div onClick={stopPropagation}>
            <YouTubePlayer
              url={videoSelected}
              className="video-player"
              playing
              controls
              loop
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                    disablePictureInPicture: true,
                  },
                  style: {
                    pointerEvents: "none",
                  },
                },
              }}
            />
          </div>
        ) : (
          <div className="video-player-text">
            Este vídeo aun no esta disponible.
          </div>
        )}
      </div>
    </>
  );
}
export default Videos;
