import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import MusicCard from "../common/MusicCard";

export const AdminMusicNew = () => {
  const { register, watch } = useForm();
  return (
    <div>
      <Helmet>
        <title>Nuevo Lanzamiento</title>
      </Helmet>
      <div className="preview">
        <MusicCard
          title={watch("title")}
          type={watch("format")}
          show={watch("show")}
          path={watch("path")}
          spotify={watch("spotify")}
          youtube={watch("youtube")}
        />
      </div>
      <form className="form">
        <div className="block">
          <label htmlFor="title">Título:</label>
          <input
            type="text"
            id="title"
            placeholder="Título del lanzamiento"
            {...register("title", { required: true })}
          />
        </div>
        <div className="block">
          <label htmlFor="cover">Portada:</label>
          <input
            type="text"
            id="cover"
            placeholder="Portada del lanzamiento"
            {...register("covers[0]", { required: true })}
          />
        </div>
        <div className="block">
          <label htmlFor="format">Formato:</label>
          <div className="select">
            <select
              id="format"
              title="Formato del lanzamiento"
              placeholder="Formato del lanzamiento"
              {...register("format", { required: true })}
            >
              <option value="Sencillo">Sencillo</option>
              <option value="EP">EP</option>
              <option value="Álbum">Álbum</option>
            </select>
            <FontAwesomeIcon icon={faAngleDown} size="sm" />
          </div>
        </div>
        <div className="block">
          <label htmlFor="date">Fecha:</label>
          <input
            type="date"
            id="date"
            placeholder="Fecha del lanzamiento"
            {...register("date", { required: true })}
          />
        </div>
        <div className="block">
          <label htmlFor="show">Visibilidad:</label>
          <div className="select">
            <select
              id="show"
              title="Visibilidad del lanzamiento"
              placeholder="Visibilidad del lanzamiento"
              {...register("show", { required: true })}
            >
              <option value="false">Privado</option>
              <option value="true">Publico</option>
            </select>
            <FontAwesomeIcon icon={faAngleDown} size="sm" />
          </div>
        </div>
        <div className="block">
          <label htmlFor="path">Ruta:</label>
          <input
            type="text"
            id="path"
            placeholder="Ruta del lanzamiento"
            {...register("path", { required: true })}
          />
        </div>
        <div className="block">
          <label htmlFor="spotify">Spotify:</label>
          <input
            type="text"
            id="spotify"
            placeholder="Enlace del lanzamiento a Spotify"
            {...register("spotify")}
          />
        </div>
        <div className="block">
          <label htmlFor="youtube">YouTube:</label>
          <input
            type="text"
            id="youtube"
            placeholder="Enlace del lanzamiento a YouTube"
            {...register("youtube")}
          />
        </div>
        <div className="block">
          <label htmlFor="">Canciones:</label>
          <input type="text" placeholder="" />
        </div>
        <div className="block">
          <label htmlFor="">Artista:</label>
          <input type="text" placeholder="" />
        </div>
        <div className="block">
          <label htmlFor="">Compositor:</label>
          <input type="text" placeholder="" />
        </div>
        <div className="block">
          <label htmlFor="">Productor:</label>
          <input type="text" placeholder="" />
        </div>
        <div className="block">
          <label htmlFor="">Discográfica:</label>
          <input
            type="text"
            placeholder="Discográfica del artista"
            value="Independiente"
          />
        </div>
        <div className="block">
          <button type="button" className="button primary-button">
            Finalizar Lanzamiento
          </button>
        </div>
      </form>
    </div>
  );
};
