import { useState } from "react";
import { Helmet } from "react-helmet";
import { db } from "../../firebase/config";
import { useTranslation } from "react-i18next";
import MusicCard from "../common/MusicCard";
import MusicCardLoad from "../common/MusicCardLoad";
import { useMusic } from "../../firebase/fbQueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faFilter,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { website_name } from "../../database/config";

function Music() {
  const { t } = useTranslation();
  const music = useMusic(db);

  const [selectedType, setSelectedType] = useState("all");

  const sameTypeMusic = music.filter(
    (music) => selectedType === "all" || music.type.includes(selectedType)
  );

  const [searchTerm, setSearchTerm] = useState("");

  const sameTitleMusic = sameTypeMusic.filter((music) => {
    const matchesSearch = music.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    return matchesSearch;
  });

  return (
    <>
      <Helmet>
        <title>
          {t("title.music")} ~ {website_name}
        </title>
        <link rel="canonical" href="https://danielospid.com/music" />
        <meta property="og:title" content="Música ~ Daniel Ospid" />
        <meta property="og:url" content="https://danielospid.com/music" />
        <meta name="twitter:title" content="Música ~ Daniel Ospid" />
        <meta name="twitter:url" content="https://danielospid.com/music" />
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="row-title">
            <h1 hidden>{t("title.music")} de {website_name}</h1>
            <h2>{t("title.music")}</h2>
            <div className="flex-action">
              <div className="select-wrapper">
                <input
                  type="text"
                  placeholder={t("music.label.search")}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <FontAwesomeIcon icon={faSearch} size="sm" color="#757575" />
              </div>
              <div className="select-wrapper">
                <select
                  name="selection of order"
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)}
                >
                  <option value="all" selected disabled>
                    {t("music.label.order")}
                  </option>
                  <option value="all">Todos</option>
                  <option value="EP">EP</option>
                  <option value="Álbum">Álbumes</option>
                  <option value="Sencillo">Sencillos</option>
                </select>
                <FontAwesomeIcon icon={faAngleDown} id="iconDown" />
                <FontAwesomeIcon icon={faFilter} id="iconFilter" />
              </div>
            </div>
          </div>
          <div className="grid">
            {sameTitleMusic.length === 0 ? (
              <>
                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />

                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />
              </>
            ) : (
              sameTitleMusic
                .sort((a, b) => b.date - a.date)
                .map((music, index) => <MusicCard key={index} {...music} />)
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Music;
