import NewsletterForm from "./NewsletterForm";

function NewsLetter() {

  return (
    <div className="footer-newsletter">
      <NewsletterForm title={true} />
    </div>
  );
}
export default NewsLetter;
