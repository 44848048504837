import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useConfigWebsite } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";

function NavMenu(props) {
  const { t } = useTranslation();
  const configWebsite = useConfigWebsite(db);

  return (
    <nav className="nav-menu" id="navMenu">
      <ul className="nav-list">
        <li className="nav-item" onClick={props.click}>
          <Link to="/music" className="nav-link">
            {t("title.music")}
          </Link>
        </li>
        <li className="nav-item" onClick={props.click}>
          <Link to="/videos" className="nav-link">
            {t("title.videos")}
          </Link>
        </li>
        {configWebsite.show_store && (
          <li className="nav-item" onClick={props.click}>
            <Link to="/shop" className="nav-link">
              {t("title.shop")}
            </Link>
          </li>
        )}
        <li className="nav-item" onClick={props.click}>
          <Link to="/about" className="nav-link">
            {t("title.about")}
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default NavMenu;
