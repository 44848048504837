import { Link } from "react-router-dom";
import { db } from "../../firebase/config";
import { useTranslation } from "react-i18next";
import useIsAdmin, { useConfigWebsite } from "../../firebase/fbQueries";

function ProductCard(props) {
  const { t } = useTranslation();
  const isAdmin = useIsAdmin(db);
  const configWebsite = useConfigWebsite(db);

  return (
    <>
      {(props.show || isAdmin) && (
        <div className="product-card">
          {configWebsite.show_store ? (
            <>
              <a
                href={`https://shop.danielospid.com/products/${props.path}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="product-figure">
                  <div className="product-state">
                    {props.presale && (
                      <span className="state presale">
                        {t("store.product.pre")}
                      </span>
                    )}
                    {props.dto && (
                      <span className="state dto">
                        {t("store.product.dto")}
                      </span>
                    )}
                    {props.sold && (
                      <span className="state sold">
                        {t("store.product.sot")}
                      </span>
                    )}
                  </div>
                  <img
                    src={props.img_def}
                    alt={props.title}
                    title={props.title}
                    className="img_def"
                    loading="eager"
                    width={297}
                    height={297}
                  />
                  {props.img_hov && (
                    <>
                      <div className="back-background"></div>
                      <img
                        src={props.img_hov}
                        alt={props.title}
                        title={props.title}
                        className="img_hov"
                        loading="eager"
                        width={297}
                        height={297}
                      />
                    </>
                  )}
                  <div className="product-screen"></div>
                </div>
              </a>
              <div className="product-details">
                <div className="product-details-title">
                  <a
                    href={`https://shop.danielospid.com/products/${props.path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={props.title}
                  >
                    <h3>{props.title}</h3>
                  </a>
                </div>
                <div className="product-details-price">
                  ${props.price.toFixed(2)}{" "}
                  {props.until && <> - ${props.until.toFixed(2)}</>}
                </div>
              </div>
            </>
          ) : (
            <>
              <Link to={`/products/${props.path}`}>
                <div className="product-figure">
                  <div className="product-state">
                    {props.presale && (
                      <span className="state presale">
                        {t("store.product.pre")}
                      </span>
                    )}
                    {props.dto && (
                      <span className="state dto">
                        {t("store.product.dto")}
                      </span>
                    )}
                    {props.sold && (
                      <span className="state sold">
                        {t("store.product.sot")}
                      </span>
                    )}
                  </div>
                  <img
                    src={props.img_def}
                    alt={props.title}
                    title={props.title}
                    className="img_def"
                    loading="eager"
                    width={297}
                    height={297}
                  />
                  {props.img_hov && (
                    <>
                      <div className="back-background"></div>
                      <img
                        src={props.img_hov}
                        alt={props.title}
                        title={props.title}
                        className="img_hov"
                        loading="eager"
                        width={297}
                        height={297}
                      />
                    </>
                  )}
                  <div className="product-screen"></div>
                </div>
              </Link>
              <div className="product-details">
                <div className="product-details-title">
                  <Link to={`/products/${props.path}`} title={props.title}>
                    <h3>{props.title}</h3>
                  </Link>
                </div>
                <div className="product-details-price">
                  ${props.price.toFixed(2)}{" "}
                  {props.until && <> - ${props.until.toFixed(2)}</>}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default ProductCard;
