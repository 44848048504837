import "../../assets/styles/app.css";

import { useEffect, useState } from "react";

import ScrollToTop from "../common/ScrollToTop";
import TopBar from "../common/TopBar";
import Header from "../common/Header";
import Menu from "../common/Menu";
import Main from "../common/Main";
import Footer from "../common/Footer";
import Glass from "../common/Glass";
import Language from "../common/Language";
import { useNavigate } from "react-router-dom";

function Layout(props) {
  const navegate = useNavigate();
  const [menu, setMenu] = useState(false);

  const handleMenu = () => {
    setMenu(!menu);
    setGlass(!glass);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "Escape") {
        navegate("/");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "KeyM" && event.altKey) {
        handleMenu();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "F2") {
        navegate("/admin/login");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "F8") {
        navegate("/admin");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  const [language, setLanguage] = useState(false);

  const handleLanguage = () => {
    setLanguage(!language);
    setGlass(true);
    setMenu(false);
  };

  const [glass, setGlass] = useState(false);

  const handleGlass = () => {
    setGlass(!glass);
    setMenu(false);
    setLanguage(false);
  };



  return (
    <div className={!menu && !props.video ? "app" : "app no-scroll"}>
      <ScrollToTop />
      <TopBar />
      <Header
        menu={menu}
        handleLanguage={handleLanguage}
        handleMenu={handleMenu}
      />
      <Menu
        menu={menu}
        handleMenu={handleMenu}
        handleLanguage={handleLanguage}
      />
      <Language language={language} handleLanguage={handleLanguage} />
      <Main />
      <Footer />
      <Glass glass={glass} handleGlass={handleGlass} />
    </div>
  );
}

export default Layout;
