import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import useIsAdmin from "./firebase/fbQueries";
import { db } from "./firebase/config";
import { useState } from "react";

import Layout from "./components/pages/Layout";
import Home from "./components/pages/Home";
import Music from "./components/pages/Music";
import MusicPage from "./components/pages/MusicPage";
import Videos from "./components/pages/Videos";
import Store from "./components/pages/Store";
import Product from "./components/pages/Product";
import Category from "./components/pages/Category";
import Privacy from "./components/pages/Privacy";
import Terms from "./components/pages/Terms";
import NoFound from "./components/pages/NoFound";
import LyricPage from "./components/pages/LyricPage";
import About from "./components/pages/About";

import Login from "./components/pages/Login";
import Admin from "./components/admin/Admin";
import AdminMusic from "./components/admin/AdminMusic";
import AdminVideos from "./components/admin/AdminVideos";
import AdminFans from "./components/admin/AdminFans";
import AdminMessages from "./components/pages/Messages";
import AdminProfile from "./components/admin/AdminProfile";
import AdminDashboard from "./components/admin/AdminDashboard";
import Lyrics from "./components/pages/Lyrics";
import AdminNotices from "./components/admin/AdminNotices";
import AdminMusicHome from "./components/admin/AdminMusicHome";
import AdminNoticesHome from "./components/admin/AdminNoticesHome";
import AdminNoticesCreate from "./components/admin/AdminNoticesCreate";
import AdminVideosHome from "./components/admin/AdminVideosHome";
import Subscribe from "./components/pages/Subscribe";
import { AdminMusicNew } from "./components/admin/AdminMusicNew";
import Policies from "./components/pages/Policies";
import Sitemap from "./components/pages/Sitemap";

function App() {
  const isAdmin = useIsAdmin(db);

  const [video, setVideo] = useState(false);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout video={video} />}>
          <Route
            path="/"
            element={<Home video={video} setVideo={setVideo} />}
          />
          <Route path="/music" element={<Music />} />
          <Route path="/music/:path" element={<MusicPage />} />
          <Route path="/videos" element={<Videos video={video} setVideo={setVideo} />} />
          <Route path="/lyrics" element={<Lyrics />} />
          <Route path="/lyrics/:path" element={<LyricPage />} />
          <Route path="/shop" element={<Store />} />
          <Route path="/products/:path" element={<Product />} />
          <Route path="/categories/:category" element={<Category />} />
          <Route path="/about" element={<About />} />
          <Route path="/subscribe" element={<Subscribe />} />
          <Route path="/policies" element={<Policies />} />
          <Route path="/policies/privacy" element={<Privacy />} />
          <Route path="/policies/terms" element={<Terms />} />
          <Route path="/sitemap" element={<Sitemap />} />

          {isAdmin ? (
          <Route element={<Admin />}>
            <Route path="/admin" element={<AdminDashboard />} />
            <Route element={<AdminMusic />}>
              <Route path="/admin/music" element={<AdminMusicHome />} />
              <Route path="/admin/music/new" element={<AdminMusicNew />} />
            </Route>
            <Route element={<AdminVideos />}>
              <Route path="/admin/videos" element={<AdminVideosHome />} />
              <Route
                path="/admin/videos/create"
                element={<AdminVideosHome />}
              />
              <Route
                path="/admin/videos/update"
                element={<AdminVideosHome />}
              />
              <Route
                path="/admin/videos/delete"
                element={<AdminVideosHome />}
              />
            </Route>
            <Route element={<AdminNotices />}>
              <Route path="/admin/notices" element={<AdminNoticesHome />} />
              <Route
                path="/admin/notices/create"
                element={<AdminNoticesCreate />}
              />
              <Route path="/admin/notices/update" element="Actualizar aviso" />
              <Route path="/admin/notices/delete" element="Eliminar aviso" />
            </Route>
            <Route path="/admin/messages" element={<AdminMessages />} />
            <Route path="/admin/stock" element={"Stock..."} />
            <Route path="/admin/fans" element={<AdminFans />} />
            <Route path="/admin/profile" element={<AdminProfile />} />
          </Route>
          ) : (
            <>
              <Route path="/admin/*" element={<Navigate to="/" replace />} />
            </>
          )}

          <Route path="/index.html" element={<Navigate to="/" replace />} />
          <Route path="/index" element={<Navigate to="/" replace />} />
          <Route path="/home" element={<Navigate to="/" replace />} />
          <Route path="/inicio" element={<Navigate to="/" replace />} />
          <Route path="/tours" element={<Navigate to="/" replace />} />
          <Route path="/musica" element={<Navigate to="/music" replace />} />
          <Route path="/música" element={<Navigate to="/music" replace />} />
          <Route path="/fotos" element={<Navigate to="/photos" replace />} />
          <Route path="/tienda" element={<Navigate to="/shop" replace />} />
          <Route path="/products" element={<Navigate to="/shop" replace />} />
          <Route path="/productos" element={<Navigate to="/shop" replace />} />
          <Route
            path="/categories"
            element={<Navigate to="/shop" replace />}
          />
          <Route
            path="/categorias"
            element={<Navigate to="/shop" replace />}
          />
          <Route path="*" element={<NoFound />} />
        </Route>

        <Route path="/admin/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
