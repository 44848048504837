import { useTranslation } from "react-i18next";
import { db } from "../../firebase/config";
import MusicCard from "../common/MusicCard";
import MusicCardLoad from "../common/MusicCardLoad";
import VideoCard from "../common/VideoCard";
import VideoCardLoad from "../common/VideoCardLoad";
import PhotoCard from "../common/PhotoCard";
import PhotoCardLoad from "../common/PhotoCardLoad";
import ProductCard from "../common/ProductCard";
import ProductCardLoad from "../common/ProductCardLoad";
import PrimaryLink from "../common/PrimaryLink";
import {
  useConfigWebsite,
  useMusic,
  usePhotos,
  useProducts,
  useVideos,
} from "../../firebase/fbQueries";
import { useState } from "react";
import ReactPlayer from "react-player";
import Banner from "../common/Banner";

function Home(props) {
  const { t } = useTranslation();
  const music = useMusic(db);
  const videos = useVideos(db);
  const photos = usePhotos(db);
  const products = useProducts(db);
  const configWebsite = useConfigWebsite(db);

  const [videoModal, setVideoModal] = useState(false);
  const [videoSelected, setVideoSelected] = useState(null);

  const changeVideo = (link) => {
    setVideoSelected(link);
    setVideoModal(!videoModal);
    props.setVideo(!props.video);
  };

  return (
    <>
      <Banner />

      {/* Música */}
      <section className="section">
        <div className="container">
          <div className="row-title">
            <h2>{t("music.title")}</h2>
          </div>
          <div className="grid">
            {music.length === 0 ? (
              <>
                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />
              </>
            ) : (
              music
                .sort((a, b) => b.date - a.date)
                .filter((music) => music.show)
                .slice(0, 4)
                .map((music, index) => <MusicCard key={index} {...music} />)
            )}
          </div>
          <div className="row-action">
            <PrimaryLink path="/music" text={t("home.button.music")} />
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <hr />
        </div>
      </section>

      {/* Vídeos */}
      <section className="section">
        <div className="container">
          <div className="row-title">
            <h2>{t("videos.title")}</h2>
          </div>
          <div className="grid">
            {videos.length === 0 ? (
              <>
                <VideoCardLoad />
                <VideoCardLoad />
                <VideoCardLoad />

                <VideoCardLoad />
                <VideoCardLoad />
                <VideoCardLoad />
              </>
            ) : (
              videos
                .sort((a, b) => b.date - a.date)
                .slice(0, 6)
                .map((video, index) => (
                  <VideoCard key={index} {...video} changeVideo={changeVideo} />
                ))
            )}
          </div>
          <div className="row-action">
            <PrimaryLink path="/videos" text={t("home.button.videos")} />
          </div>
        </div>
      </section>

      <div
        className={!videoModal ? "video-modal" : "video-modal show"}
        onClick={() => changeVideo(null)}
      >
        {videoSelected ? (
          <ReactPlayer
            url={videoSelected}
            className="video-player"
            playing
            controls
          />
        ) : (
          <div className="video-player-text">
            Este vídeo aun no esta disponible.
          </div>
        )}
      </div>

      {false && (
        <>
          {photos.every((photo) => photo.show === false) ? null : (
            <>
              <section className="section">
                <div className="container">
                  <hr />
                </div>
              </section>

              {/* Fotos */}
              <section className="section">
                <div className="container">
                  <div className="row-title">
                    <h2>{t("photos.title")}</h2>
                  </div>
                  <div className="grid">
                    {photos.length === 0 ? (
                      <>
                        <PhotoCardLoad />
                        <PhotoCardLoad />
                        <PhotoCardLoad />
                        <PhotoCardLoad />
                      </>
                    ) : (
                      photos
                        .sort((a, b) => b.date - a.date)
                        .filter((photo) => photo.show)
                        .slice(0, 4)
                        .map((photo, index) => (
                          <PhotoCard key={index} {...photo} />
                        ))
                    )}
                  </div>
                  <div className="row-action">
                    <PrimaryLink
                      path="/photos"
                      text={t("home.button.photos")}
                    />
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}

      {configWebsite.show_store && (
        <>
          <section className="section">
            <div className="container">
              <hr />
            </div>
          </section>

          {/* Productos */}
          <section className="section">
            <div className="container">
              <div className="row-title">
                <h2>{t("store.title")}</h2>
              </div>
              <div className="grid">
                {products.length === 0 ? (
                  <>
                    <ProductCardLoad />
                    <ProductCardLoad />
                    <ProductCardLoad />
                    <ProductCardLoad />
                  </>
                ) : (
                  products
                    .sort((a, b) => b.date - a.date)
                    .filter((product) => product.show)
                    .slice(0, 4)
                    .map((product, index) => (
                      <ProductCard key={index} {...product} />
                    ))
                )}
              </div>
              <div className="row-action">
                <a
                  href="https://shop.danielospid.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button primary-button"
                >
                  Ver Más Productos
                </a>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}
export default Home;
