import { useEffect, useRef } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faPause,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function Banner(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoSliding, setIsAutoSliding] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const bannerRef = useRef(null);
  const startX = useRef(0);
  const scrollLeft = useRef(0);
  const navigate = useNavigate();

  const slides = [
    {
      background: "/images/profile-large.png",
      title: "¡Bienvenidos a mi Sitio Web!",
      subtitle:
        "Descubre mi música y vídeos. Estoy emocionado de que estés aquí.",
      action1: {
        text: "Explorar Música",
        click: () => navigate("/music"),
      },
      action2: {
        text: "Explorar Vídeos",
        click: () => navigate("/videos"),
      },
    },
    // {
    //   background: "/images/banner-tour-i.png",
    //   cover: "/images/coming-soon.gif",
    //   title: "¡Nuevo Lanzamiento Muy Pronto!",
    //   subtitle:
    //     "Estará disponible en todas las plataformas. ¡No te lo pierdas!",
    //   action1: {
    //     text: "Preguardar",
    //     click: () =>
    //       window.open("https://music.danielospid.com/muy-pronto", "_blank"),
    //   },
    //   action2: {
    //     text: "Suscríbete para Actualizaciones",
    //     click: () => navigate("/subscribe"),
    //   },
    // },
    // {
    //   background: "/images/music-collection.jpg",
    //   title: "Explora Toda Mi Música",
    //   subtitle:
    //     "Descubre mis lanzamientos y álbumes exclusivos. Música hecha con pasión para todos ustedes.",
    //   action1: {
    //     text: "Escuchar Ahora",
    //     click: () => navigate("/music"),
    //   },
    //   action2: {
    //     text: "Ver Discografía",
    //     click: () => navigate("/music/discography"),
    //   },
    // },
    // {
    //   background: "/images/merch-banner.jpg",
    //   title: "¡Nueva Colección de Ropa Disponible!",
    //   subtitle:
    //     "Ropa exclusiva para mis fans: camisas, suéteres y mucho más. ¡No te quedes sin la tuya!",
    //   action1: {
    //     text: "Explorar Tienda",
    //     click: () => navigate("/shop"),
    //   },
    //   action2: {
    //     text: "Ver Colecciones",
    //     click: () => navigate("/shop/collections"),
    //   },
    // },
  ];

  const totalSlides = slides.length;

  // Función para ir a la siguiente diapositiva
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
  };

  // Función para ir a la diapositiva anterior
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? totalSlides - 1 : prevIndex - 1
    );
  };

  // Efecto para controlar el auto-desplazamiento
  useEffect(
    () => {
      if (isAutoSliding) {
        const interval = setInterval(nextSlide, 3000);
        return () => clearInterval(interval); // Limpia el intervalo al desmontar
      }
    }
    // [isAutoSliding]
  );

  // Alternar el auto-desplazamiento
  const toggleAutoSliding = () => {
    setIsAutoSliding(!isAutoSliding);
  };

  // Desplazamiento manual con el cursor
  const handleMouseDown = (e) => {
    setIsDragging(true);
    startX.current = e.clientX;
    scrollLeft.current = currentIndex;
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const walk = e.clientX - startX.current;
    const threshold = 50; // Sensibilidad al arrastrar
    if (walk > threshold) {
      prevSlide();
      setIsDragging(false);
    } else if (walk < -threshold) {
      nextSlide();
      setIsDragging(false);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <section
      className="banner"
      ref={bannerRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      style={{ cursor: isDragging ? "grabbing" : "grab" }}
    >
      <div
        className="slides"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div
            className="slide"
            key={index}
            style={{ backgroundImage: `URL(${slide.background})` }}
          >
            {(slide.title || slide.subtitle) && (
              <div className="content">
                {slide.cover && (
                  <img
                    src={slide.cover}
                    alt={`Portada de ${slide.title}`}
                    title={`Portada de ${slide.title}`}
                    className="cover"
                    loading="eager"
                    width={390}
                    height={390}
                  />
                )}
                {slide.title && index === 0 ? (
                  <h1 className="title">{slide.title}</h1>
                ) : (
                  <div className="title">{slide.title}</div>
                )}
                {slide.subtitle && <p className="subtitle">{slide.subtitle}</p>}
                {(slide.action1.text || slide.action2.text) && (
                  <div className="actions">
                    {slide.action1.text && slide.action1.click && (
                      <button type="button" onClick={slide.action1.click}>
                        {slide.action1.text}
                      </button>
                    )}
                    {slide.action2.text && slide.action2.click && (
                      <button type="button" onClick={slide.action2.click}>
                        {slide.action2.text}
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      {slides.length > 1 && (
        <>
          <div className="controls">
            <button
              type="button"
              title="Anterior"
              className="button icon"
              onClick={prevSlide}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="lg" />
            </button>
            <button
              type="button"
              title="Siguiente"
              className="button icon"
              onClick={nextSlide}
            >
              <FontAwesomeIcon icon={faArrowRight} size="lg" />
            </button>
          </div>
          <button
            type="button"
            title={isAutoSliding ? "Pausar" : "Reproducir"}
            className="stop-btn"
            onClick={toggleAutoSliding}
          >
            <FontAwesomeIcon
              icon={isAutoSliding ? faPause : faPlay}
              size="2x"
            />
          </button>
        </>
      )}
    </section>
  );
}
export default Banner;
