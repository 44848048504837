function Sitemap() {
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="row-title">
            <h1 hidden>Sitemap del Sitio Web de Daniel Ospid</h1>
            <h2>Sitemap</h2>
          </div>
        </div>
      </section>
    </>
  );
}
export default Sitemap;
