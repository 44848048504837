import { Helmet } from "react-helmet";
import { Link, Outlet } from "react-router-dom";
import { website_name } from "../../database/config";

function Music() {
  return (
    <>
      <Helmet>
        <title>Música - {website_name}</title>
      </Helmet>

      <div className="admin-header">
        <div className="flex">
          <div className="title">Música</div>
        </div>
        <div className="flex">
          <Link to="/admin/music">Inicio</Link>
          <Link to="/admin/music/new">Nuevo lanzamiento</Link>
        </div>
      </div>

      <div className="admin-body">
        <Outlet />
      </div>
    </>
  );
}
export default Music;
