import { Helmet } from "react-helmet";
import { website_name } from "../../database/config";

function Privacy() {
  return (
    <>
      <Helmet>
        <title>Política de Privacidad ~ {website_name}</title>
        <link rel="canonical" href="https://danielospid.com/policies/privacy" />
        <meta property="og:title" content="Política de Privacidad ~ Daniel Ospid" />
        <meta property="og:url" content="https://danielospid.com/policies/privacy" />
        <meta name="twitter:title" content="Política de Privacidad ~ Daniel Ospid" />
        <meta name="twitter:url" content="https://danielospid.com/policies/privacy" />
      </Helmet>

      <section className="section">
        <div className="container">
          <article className="article">
            <h1>
              Política de Privacidad
            </h1>
            <hr />
            <p>
              En este sitio web, nos tomamos muy en serio la privacidad de
              nuestros usuarios. Esta política de privacidad explica cómo
              recopilamos, usamos y protegemos la información personal que nos
              proporcionas.
            </p>
            <p>
              <h2>Información que recopilamos: </h2>
              Recopilamos información personal, como tu nombre, dirección de
              correo electrónico y otra información que nos proporciones
              voluntariamente a través del sitio web. También podemos recopilar
              información no personal, como tu dirección IP, el tipo de
              navegador que utilizas y otros datos técnicos relacionados con tu
              uso del sitio web.
            </p>
            <p>
              <h2>Uso de la información: </h2>
              Utilizamos la información que recopilamos para responder a tus
              consultas, procesar tus pedidos, mejorar nuestro sitio web y
              comunicarnos contigo sobre nuestros productos y servicios. También
              podemos utilizar la información para fines de marketing, como
              enviar correos electrónicos promocionales.
            </p>
            <p>
              <h2>Compartir de la información: </h2>
              No vendemos ni alquilamos tu información personal a terceros.
              Podemos compartir tu información con proveedores de servicios que
              trabajan en nuestro nombre y que necesitan acceso a tu información
              para brindar servicios para nosotros.
            </p>
            <p>
              <h2>Seguridad de la información: </h2>
              Tomamos medidas razonables para proteger la información personal
              que recopilamos contra el acceso no autorizado, la divulgación, la
              alteración o la destrucción.
            </p>
            <p>
              <h2>Cambios en la política de privacidad: </h2>
              Podemos actualizar esta política de privacidad de vez en cuando.
              Cualquier cambio en esta política de privacidad será publicado en
              esta página y sin previo aviso.
            </p>
            <p>
              Al utilizar este sitio web, estas aceptando esta política de
              privacidad en su totalidad. Si no estás de acuerdo con esta
              política de privacidad, no utilices este sitio web.
            </p>
            <p>
              <b>Última actualización:</b> 08 de julio de 2024
            </p>
          </article>
        </div>
      </section>
    </>
  );
}
export default Privacy;
