import { Helmet } from "react-helmet";
import { website_name } from "../../database/config";
import { Link } from "react-router-dom";

function Policies() {
  return (
    <>
      <Helmet>
        <title>Políticas ~ {website_name}</title>
        <meta
          property="og:url"
          content="https://danielospid.com/policies/cookies"
        />
        <link rel="canonical" href={`${window.location.origin}/policies`} />
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="row-title">
            <h1 hidden>Políticas del Sitio Web de {website_name}</h1>
            <h2>Políticas</h2>
            <div className="flex-action"></div>
          </div>
          <Link to={`/policies/privacy`}>Política de Privacidad</Link>
          <Link to={`/policies/terms`}>Terminos & Condiciones</Link>
        </div>
      </section>
    </>
  );
}
export default Policies;
