import { Helmet } from "react-helmet";
import { website_name } from "../../database/config";
import NewsletterForm from "../common/NewsletterForm";

function Subscribe() {
  const faqs = [
    {
      q: "¿Por qué suscríbirme al boletín?",
      a: "Al suscribirte al boletín, serás parte de mi círculo más cercano de seguidores. Recibirás noticias exclusivas sobre mis próximos lanzamientos, contenido detrás de cámaras, descuentos especiales en productos, y adelantos de nuevas canciones. También serás de los primeros en enterarte de cualquier novedad o colaboración.",
    },
    {
      q: "¿Qué tipo de contenido recibiré en el boletín?",
      a: "En el boletín, recibirás actualizaciones exclusivas sobre mi música, nuevos lanzamientos, colaboraciones, fechas de conciertos y promociones especiales para mis seguidores más fieles.",
    },
    {
      q: "¿Con qué frecuencia recibiré correos electrónicos?",
      a: "Envío correos electrónicos una vez al mes o cuando haya un anuncio importante, como un nuevo sencillo o un lanzamiento especial. No te preocupes, no saturaré tu bandeja de entrada.",
    },
    {
      q: "¿Cómo puedo suscribirme al boletín?",
      a: "Simplemente llena el formulario de suscripción con tu nombre, apellido, país y correo electrónico en esta página, ¡y listo!",
    },
    {
      q: "¿Es gratis suscribirse al boletín?",
      a: "Sí, suscribirte al boletín es completamente gratis. Solo necesitas proporcionar tu correo electrónico.",
    },
    {
      q: "¿Puedo cancelar mi suscripción en cualquier momento?",
      a: 'Por supuesto, puedes cancelar tu suscripción en cualquier momento haciendo clic en el enlace de "Cancelar suscripción" que encontrarás en la parte inferior de cada correo electrónico que te enviemos.',
    },
    {
      q: "¿Mi información estará segura?",
      a: "Tu privacidad es muy importante para mi. No comparto tu información personal con terceros y solo la utilizo para enviarte el boletín. Para más detalles, puedes consultar la página de la Política de Privacidad.",
    },
    {
      q: "¿Qué beneficios obtendré al suscribirme?",
      a: "Como suscriptor, tendrás acceso exclusivo a contenido detrás de cámaras, adelantos de nuevos lanzamientos y descuentos especiales en productos y música.",
    },
    {
      q: "¿Recibiré notificaciones de nuevas canciones o colaboraciones?",
      a: "Sí, te mantendré al tanto de cualquier nueva canción, colaboración o proyecto en el que esté trabajando. ¡Serás de los primeros en enterarte!",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Suscríbete al Boletín ~ {website_name}</title>
        <link rel="canonical" href="https://danielospid.com/subscribe" />   
        <meta property="og:title" content="Suscríbete al Boletín ~ Daniel Ospid" />
        <meta property="og:url" content="https://danielospid.com/subscribe" />
        <meta name="twitter:title" content="Suscríbete al Boletín ~ Daniel Ospid" />
        <meta name="twitter:url" content="https://danielospid.com/subscribe" />
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="block-title">
            <h1>Suscríbete, Mantente Actualizado</h1>
          </div>
          <div className="block-content">
            <div className="container">
              <NewsletterForm />
              <hr />
              <div className="faqs">
                {faqs.map((question, index) => (
                  <div className="question">
                    <h2>{question.q}</h2>
                    <p key={index}>{question.a}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Subscribe;
